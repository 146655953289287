<template>
	<div>
		
		<div class="webflux-iubenda-showCP-container">
			<button class="webflux-iubenda-showCP-btn iubenda-cs-preferences-link">cookies beheren</button>
		</div>

	</div>
</template>


<script>
export default {
	name: 'IubendaCookiePopup',
	mounted() {
		this.initBanner();
	},
	methods: {
		initBanner: function(){
			
			_iub.csConfiguration = {
				"lang": "nl",
				"cookiePolicyId":13467565,
				"siteId": 2049921,
				"priorConsent":false,
				"reloadOnConsent":false,
				"perPurposeConsent":false,
				"cookiePolicyInOtherWindow":true,
				"consentOnContinuedBrowsing":false,
				"rebuildIframe":false,
				"preserveOriginalClasses":true,
				"preserveIubClasses":true,
			
					"banner":{ 
						"acceptButtonDisplay":true,
						"customizeButtonDisplay":true,
						"position":"float-bottom-left",
						"slideDown":false,
						"prependOnBody":false,
						"applyStyles":false,
						"rejectButtonDisplay":false
						
						
					},
					"preferenceCookie": {
						"expireAfter": 180
					}
			};

			// inject script to head
			let newScript = document.createElement("script");      
			newScript.setAttribute('src', 'https://cdn.iubenda.com/cs/iubenda_cs.js');
			document.body.appendChild(newScript);
		}
	}
}
</script>

<style>
	html{overflow:visible  !important;}
	.webflux-iubenda-showCP-container{
		position:fixed;
		left:20px;
		bottom:20px;
		max-width: 320px;
		border-radius: 0px;
		z-index:500;
	}
		.webflux-iubenda-showCP-btn{
			border:none !important;
			background-color:none !important;
			padding:5px 8px 6px 5px !important;
			cursor:pointer;
			border-radius: 0px;
			font-family: "Verdana" !important;
			font-size:10px !important;
			text-transform:lowercase !important;
			font-size:10px !important;
			background:#fff;
			color:#888;
			box-shadow:0px 0px 10px rgba(0, 0, 0, 0.05) !important;
		}
		.webflux-iubenda-showCP-btn:hover{
			background:#888;
			color:#fff;
		}

	.iubenda-cs-container{
		left:20px;
		bottom:20px !important;
		z-index: 550 !important;
		position:fixed !important;
		max-width: 280px;
		padding:12px 14px 14px 14px !important;
		box-shadow:50px 0px 400px rgba(0, 0, 0, 0.6) !important;
		border-radius: 0px;
		background: #f9f9f9;  
		border-top:4px solid #fff;
	}
		#iubenda-cs-title {
			font-family: "Verdana" !important;
			font-size:18px !important;
			line-height: 18px !important;
			padding-right:30px !important;
			color:#666  !important;
			text-transform: uppercase  !important;
			margin-bottom: 8px !important;
			margin-top:4px !important;
		}
		#iubenda-cs-paragraph{
			font-family: "Verdana" !important;
			font-size:12px !important;
			margin-bottom: 0px !important;
			padding:10px 0px !important;
			line-height: 18px !important;
			margin-bottom:12px !important;
			color:#666 !important;
		}
		.iubenda-cs-opt-group-custom{
			padding-bottom:5px;
		}
			.iubenda-cs-customize-btn, .iubenda-cs-accept-btn, .iubenda-cs-reject-btn{
				border:none !important;
				background-color:none !important;
				padding:10px 8px 11px 8px !important;
				cursor:pointer;
				width:100% !important;
				display:block !important;
				border-radius:0px;
				font-family: "Verdana" !important;
				font-size:11px !important;
				text-transform:lowercase !important;
			}
				.iubenda-cs-customize-btn:hover,.iubenda-cs-reject-btn:hover{
					background:#ccc;
				}

		.iubenda-cs-close-btn{
			display:none !important;
		}
		.iubenda-cs-accept-btn{
			font-weight:bold !important;
			font-size:14px !important;
			line-height:14px !important;
			background:#00a86b;
			padding:20px 10px !important;
			text-transform:uppercase !important;
			color:#fff;
			margin-top:10px !important;
			position:relative !important;
		}
		.iubenda-cs-accept-btn:after{
			content:"✓";
			position:absolute;
			right:15px;
			top:12px;
			font-size:21px;
			width:30px;
			height:30px;
			line-height:30px;
			border-radius:30px;
			background:#fff;
			color:#00a86b;
		}
			.iubenda-cs-accept-btn:hover{
				background:#008353;
			}
</style>