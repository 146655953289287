<template>
  <div id="app">
    
    <Header v-if="$route.name !== 'voucher' && $route.name !== 'login' && $route.name !== 'error' " />
    <router-view :key="$route.path" />

    <div class="loader" v-bind:class="{active: $globalVariables.loading}">
      <div class="lds-ripple"><div></div><div></div></div>
    </div>

  </div><!-- /app -->
</template>

<script>
// @ is an alias to /src
import Header from '@/global/Header.vue'
import Pagetitle from '@/global/Pagetitle.vue'

export default {
	name: 'App',
	components: {
		Header,
    Pagetitle
	}
}
</script>

<style lang="scss">
    @import '@/scss/main.scss';

    /* ====================
      TABLE
    ====================== */
    .vgt-table {
        font-size: 15px !important;
        th { font-weight: $fw-bold; }
        tbody th { padding: .75em .75em .75em .75em !important; }
        &.cellPointer {
          td { cursor: pointer !important; }
        }
        tbody td { word-break: break-word; }
    }
    .vgt-wrap__footer {
        .footer__navigation__page-btn {
            .chevron.right::after { border-left-color: $color-blue-darker !important; } 
            .chevron.left::after { border-right-color: $color-blue-darker !important; }
        }
    } 
    .vgt-select {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4MTkiIGhlaWdodD0iMTAyNCI+PHBhdGggZD0iTTQwOS41IDgzOS42ODFMLS4wMDEgMTg0LjQ3OWg4MTkuMDAyeiIvPjwvc3ZnPg==);
        background-repeat: no-repeat,repeat;
        background-position: right 1em top 50%,0 0;
        background-size: 0.55em auto,100%;
        line-height: 1.2 !important;
    }
    .vgt-wrap {
        .selection-info-wrap {
            overflow: hidden;
            display: flex;
            align-items: center;
            background-color: $color-blue-dark;
            color: white;
            padding: 15px 16px;

            .vgt-selection-info-row__actions {
                margin-left: auto;
            }
        }
        .vgt-wrap__footer {
          .footer__row-count__label, .footer__row-count__select, .footer__navigation, .footer__navigation__page-btn span {
            font-size: 15px;
          }
        } 
    }
    

    /* ====================
      TOAST
    ====================== */
    .dtoast-ctn {
        position: fixed !important;
    }
    .dtoast-ctn .dtoast-dub {
        font-family: $font-primary;
        
        .dt-struct .dt-body {
            .dt-body-heading {
                font-size: 16px;
                font-weight: $fw-semibold;
            }
            .dt-body-content {
                &:empty {
                    display: none;
                }
            }
        } 
    } 
</style>
