<template src="@/views/LoginTpl.vue"></template>

<script>

import axios from "axios"


export default {
	name: 'Login',
	props: {
		pagetitle: String
	},
	data(){
		return {
			email: '',
			password: '',
			error: false
		};
	},
	mounted() {
		//this.$cookies.remove("user_session");
	},
	methods: {
		onLogin: function(e) {
			let _this = this;

			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/tokens/create',   
				responseType: 'json',
				data: {
					email: this.email,
					password: this.password,
					token_name: 'obentoken'
				}
			})
			.then(function (response) {
				let redirectFromApi = '/clients/';
				if (response.data.data.role === 'partner') {
					redirectFromApi = '/invoicing/';
				}

				_this.$cookies.remove("user_session");
				_this.$cookies.set("user_session",response.data.data.token, 0);
				_this.$cookies.set("user_role",response.data.data.role, 0);

				_this.$router.push({ path: redirectFromApi });
				
			})
			.catch(function({response}){
				_this.error = true;
			});

			e.preventDefault();
			
		}
	}
}
</script>

