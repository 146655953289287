import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Helpers from './global/Helpers';
import GlobalVariables from './global/GlobalVariables';
import VueDateFns from "vue-date-fns";
import 'dtoaster/dist/dtoaster.css';
import DToaster from 'dtoaster';
import ToasterPresets from './global/ToastPresets.json'; //Your predefined toasts presets (optionally)
import VueFinalModal from 'vue-final-modal';
import VueCookies from 'vue-cookies';
import axios from "axios";



Vue.config.productionTip = false
Vue.use(VueDateFns);

Vue.use(VueFinalModal());

Vue.use(VueCookies);

axios.interceptors.response.use(response => {
  return response;
}, error => {
  // soft errors (validation, page not found, ...)
  if (error.response.status === 400 || error.response.status === 410 || error.response.status === 404) {
    return response;
  } else {
    // unauthorized
    if (error.response.status === 401) {
      $cookies.remove("user_session");
      GlobalVariables.apiToken = '';
      router.push({ path: '/login/' });
    } 
    else {
      router.push({ path: '/error/', query: { code: error.response.status }  });
    }
  }
  return error;
});

// global variables
Vue.prototype.$globalVariables = GlobalVariables;

// custom helpers
Vue.use({
  install() { 
      Vue.helpers = Helpers;
      Vue.prototype.$helpers = Helpers;
  }
});

Vue.use(DToaster, {
  presets: ToasterPresets,
  position: 'bottom-left', //toasts container position on the screen
  containerOffset: '30px', //toasts container offset from top/bottom of the screen
  containerSideOffset: '30px'
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
