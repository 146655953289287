export default {
	loading: false,
	apiUrl: 'https://bonnen.oben.be/api',
	apiToken: '',
	userRole: '',
	statuses: [
		{ value: 'new', text: 'Nieuw' },
		{ value: 'request_sent', text: 'Vraag om gegevens aanvullen verzonden' },
		{ value: 'approval_needed', text: 'Goedkeuring vereist' },
		{ value: 'approved', text: 'Gegevens goedgekeurd' },
		{ value: 'reminder_one_sent', text: 'Herinnering 1 verstuurd' },
		{ value: 'reminder_one_expired', text: 'Geen reactie herinnering 1' },
		{ value: 'reminder_two_sent', text: 'Herinnering 2 verstuurd' },
		{ value: 'voucher_sent', text: 'Bon verstuurd' },
		{ value: 'voucher_redeemed', text: 'Bon afgehaald' },		
		{ value: 'cancelled', text: 'Stopgezet' }
	]
}