import GlobalVariables from '../global/GlobalVariables'
import router from '../router'
import { format, parse } from 'date-fns'

export default {
    isAuthenticated: function() {
		if ($cookies.get("user_session")) {
			GlobalVariables.apiToken = $cookies.get("user_session");
			GlobalVariables.userRole = $cookies.get("user_role");
			return true;
		} else {
			return false;
		}
	},

	rootRedirectOnUserRole: function() {
		let redirectTo = '/invoicing/';
		
		// make sure cookie is read and set as global var
		this.isAuthenticated();

		if (GlobalVariables.userRole === 'superadmin' || GlobalVariables.userRole === 'oben') {
			redirectTo = '/clients/';
		}

		return redirectTo;
	},

	onLogOut: function(route) {
		$cookies.remove("user_session");
		$cookies.remove("user_role");
		GlobalVariables.apiToken = '';
		GlobalVariables.userRole = '';
		router.push({ path: '/login/' });
		return false;
	},
	
	parseDate(date) {
		return parse(date, 'dd/MM/yyyy', new Date());
	},
	
	parseDateHour(date) {
		return parse(date, 'dd/MM/yyyy HH:mm', new Date());
	},
	
	formatDate(date) {
		return format(date, 'dd/MM/yyyy');
	},

	formatDateHour(date) {
		return format(date, 'dd/MM/yyyy HH:mm');
	},

	base64ToDownload: function(base64, filetype, filename) {
        let binary = atob(base64.replace(/\s/g, ''));
		let len = binary.length;
		let buffer = new ArrayBuffer(len);
		let view = new Uint8Array(buffer);
		for (let i = 0; i < len; i++) { view[i] = binary.charCodeAt(i); }
		
		const url = window.URL.createObjectURL(new Blob([view], { type: filetype }));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
    }
};