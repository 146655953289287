import Vue from 'vue'
import VueRouter from 'vue-router'
import ClientList from '../views/ClientList.vue'
import ClientDetail from '../views/ClientDetail.vue'
import Invoicing from '../views/Invoicing.vue'
import InfoRequest from '../views/InfoRequest.vue'
import RedeemVoucher from '../views/RedeemVoucher.vue'
import Login from '../views/Login.vue'
import Error from '../views/Error.vue'
import Helpers from '../global/Helpers'

Vue.use(VueRouter)

let _this = this;

const routes = [
    {
        path: '/',
        name: 'root',
        redirect: to => {
            return Helpers.rootRedirectOnUserRole();
        }
    },
    {
        path: '/clients/',
        name: 'clients-list',
        component: ClientList,
        meta: {requiresAuth: true},
        props: route => ({ pagetitle: `Klantenbeheer` })
    },
    {
        path: '/clients/:clientId/',
        name: 'clients-detail',
        component: ClientDetail,
        meta: {requiresAuth: true},
        props: route => ({ pagetitle: `Klant:` })
    },
    {
        path: '/invoicing/',
        name: 'invoicing',
        component: Invoicing,
        meta: {requiresAuth: true},
        props: route => ({ pagetitle: `Facturatie` })
    },
    {
        path: '/info-request/',
        redirect: '/info-request/error/',
        meta: {requiresAuth: false},
    },
    {
        path: '/info-request/:clientId/',
        name: 'info-request',
        component: InfoRequest,
        meta: {requiresAuth: false},
        props: route => ({ pagetitle: `Gegevens aanvullen` })
    },
    {
        path: '/voucher/',
        name: 'voucher',
        component: RedeemVoucher,
        meta: {requiresAuth: true},
        props: route => ({ pagetitle: `Redeem Voucher` })
    },
    {
        path: '/login/',
        name: 'login',
        component: Login,
        meta: {requiresAuth: false},
        props: route => ({ pagetitle: `Login` })
    },
    {
        path: '/error/',
        name: 'error',
        component: Error,
        meta: {requiresAuth: false},
        props: route => ({ pagetitle: `Fout` })
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (Helpers.isAuthenticated()) {
            next();
            return;
        }
        next('/login/');
    } else {
        next();
    }
})

export default router;
