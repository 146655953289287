<template src="@/views/InfoRequestTpl.vue"></template>

<script>
import Pagetitle from '@/global/Pagetitle.vue'
import axios from "axios"
import IubendaCookiePopup from '@/global/IubendaCookiePopup.vue'
// import DatePicker from '@sum.cumo/vue-datepicker'
// import '@sum.cumo/vue-datepicker/dist/Datepicker.css'
// import { parse, format, startOfYesterday } from 'date-fns'

export default {
	name: 'InfoRequest',
	components: {
		Pagetitle,
		IubendaCookiePopup
		// DatePicker
	},
	props: {
		pagetitle: String
	},
	data(){
		return {
			clientId: this.$route.params.clientId,
			clientData: {
				oben_id: '',
				partner_id: 1,
				first_name: '',
				last_name: '',
				email: '',
				street: '',
				street_number: '',
				street_bus: '',
				city: '',
				zip: '',
				phone: '',
				can_contact: ''
			}
		};
	},
	mounted() {
		if (this.clientId !== 'thankyou' && this.clientId !== 'error') {
			this.fetchClientData();
		}
	},
	watch: {
		'$route': 'fetchData'
	},
	methods: {
		fetchClientData: function() {
			let _this = this;
			axios({
				method: 'get',
				url: this.$globalVariables.apiUrl + '/customer-information/' + _this.clientId,   
				responseType: 'json'
			})
			.then(function (response) {
				//console.log(response);
				_this.clientData = response.data.data;
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
				setTimeout(() => { _this.$router.push({ path: '/info-request/error/' }) }, 1000);
			});
		},

		onSaveClientData: function(e){
			let _this = this;

			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/customer-information/' + _this.clientId + '/update',   
				responseType: 'json',
				data: _this.clientData
			})
			.then(function (response) {
				//console.log(response);
				_this.$dtoast.pop({ preset: "default", heading: `Uw gegevens werden bewaard!` });
				setTimeout(() => { _this.$router.push({ path: '/info-request/thankyou/' }) }, 1000);
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
				setTimeout(() => { _this.$router.push({ path: '/info-request/error/' }) }, 1000);
			});

			e.preventDefault();
		}
	}
}
</script>