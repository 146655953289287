<template>
	<div class="hero o-h">
		<div class="row mt-md mb-md">
			<div class="grid middle between">
				<div class="col auto">
					<h1 class="section-title fw-bold">{{ pagetitle }}</h1>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Pagetitle',
	props: {
		pagetitle: String
	}
}
</script>