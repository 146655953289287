<template src="@/views/RedeemVoucherTpl.vue"></template>

<script>
import Pagetitle from '@/global/Pagetitle.vue'
import axios from "axios"
import CustomModal  from '@/global/CustomModal'

export default {
	name: 'RedeemVoucher',
	components: {
		Pagetitle,
		CustomModal
	},
	props: {
		pagetitle: String
	},
	data(){
		return {
			partnerId: this.$route.params.partnerId,
			voucherCode: '',
			showVoucherError: false,
			showVoucherSuccess: false,
			showVoucherCheckSuccess: false,
			showVoucherExpiredError: false,
			showVoucherRedeemedError: false
		};
	},
	mounted() {
		// console.log('redeem voucher');
		// console.log(this.partnerId);
	},
	watch: {
		'$route': 'fetchData'
	},
	methods: {
		onCheckVoucher: function(){
			let _this = this;

			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/vouchers/status',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken },
				data: { code: this.voucherCode }
			})
			.then(function (response) {
				switch(response.data.data) {
					case 'valid':
					case 'not sent':
						_this.showVoucherCheckSuccess = true;
						break;
					case 'not found':
						_this.showVoucherError = true;
						break;
					case 'redeemed':
						_this.showVoucherRedeemedError = true;
						break;
					case 'expired':
						_this.showVoucherExpiredError = true;
						break;
					default:
						_this.showVoucherError = true;
				}
			})
			.catch(function({response}){
				_this.showVoucherError = true;
			});
		},

		onRedeemVoucher: function(){
			let _this = this;

			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/vouchers/redeem',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken },
				data: { code: this.voucherCode }
			})
			.then(function (response) {
				_this.showVoucherCheckSuccess = false;
				_this.showVoucherSuccess = true;
				_this.voucherCode = '';
			})
			.catch(function({response}){
				_this.showVoucherError = true;
			});
		}
	}
}
</script>