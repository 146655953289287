<template src="@/views/ClientDetailTpl.vue"></template>

<script>
import Pagetitle from '@/global/Pagetitle.vue'
import axios from "axios"
import DatePicker from '@sum.cumo/vue-datepicker'
import '@sum.cumo/vue-datepicker/dist/Datepicker.css'
import { parse, format, startOfYesterday } from 'date-fns'

export default {
	name: 'ClientDetail',
	components: {
		Pagetitle,
		DatePicker
	},
	props: {
		pagetitle: String
	},
	data(){
		return {
			statuses: this.$globalVariables.statuses,
			clientId: this.$route.params.clientId,
			clientData: {
				oben_id: '',
				partner_id: 1,
				first_name: '',
				last_name: '',
				email: '',
				street: '',
				street_number: '',
				street_bus: '',
				city: '',
				zip: '',
				phone: '',
				can_contact: '',
				status: 'new',
				status_text: 'Nieuwe klant'
			},
			voucher: {
				// disabledDates: {
				// 	to: this.getDateYesterday()
				// },
				expiryDate: ''
			},
			selectedPartner: 1,
			partnerData: []
		};
	},
	mounted() {
		this.fetchPartnerData();
		if (this.clientId !== 'new') { this.fetchClientData(); }
	},
	watch: {
		'$route': 'fetchData'
	},
	methods: {
		// getDateYesterday: function() {
		// 	const today = new Date()
		// 	const yesterday = new Date(today)
		// 	yesterday.setDate(yesterday.getDate() - 1);
		// 	return yesterday;
		// },
		fetchPartnerData: function() {
			let _this = this;
			axios({
				method: 'get',
				url: this.$globalVariables.apiUrl + '/partners',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken }
			})
			.then(function (response) {
				_this.partnerData = response.data.data;
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
				setTimeout(() => { _this.$router.push({ path: '/clients/' }) }, 1000);
			});
		},
		
		onPartnerChange: function(){
			this.clientData.partner_id = this.selectedPartner;
		},

		fetchClientData: function() {
			let _this = this;
			axios({
				method: 'get',
				url: this.$globalVariables.apiUrl + '/customers/' + _this.clientId,   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken }
			})
			.then(function (response) {
				//console.log(response);
				_this.clientData = response.data.data;
				_this.clientData.partner_id = _this.clientData.partner.id;
				_this.selectedPartner = _this.clientData.partner.id;
				_this.clientData.status_text = _this.statuses.find(c => c.value === _this.clientData.status);	
				
				if(_this.clientData.latest_voucher && _this.clientData.latest_voucher.expires_at_formatted) {
					_this.voucher.expiryDate = _this.$helpers.parseDate(_this.clientData.latest_voucher.expires_at_formatted);
				}
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
				setTimeout(() => { _this.$router.push({ path: '/clients/' }) }, 1000);
			});
		},

		onSaveClientData: function(e){
			let _this = this;
			
			let apiParams = '/customers/' + _this.clientId +'/update';
			if (this.clientId === 'new') {
				apiParams = '/customers/create';
			}
			
			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + apiParams,   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken },
				data: _this.clientData
			})
			.then(function (response) {
				_this.$dtoast.pop({ preset: "default", heading: `Klantgegevens bewaard!` });
				setTimeout(() => { _this.$router.push({ path: '/clients/' }) }, 1000);
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});

			e.preventDefault();
		},		

		onUpdateVoucherExpiry: function(){
			let _this = this;
			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/customers/' + this.clientId +'/renew-voucher',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken },
				data: { date: format(this.voucher.expiryDate, 'yyyy-MM-dd') }
			})
			.then(function (response) {
				_this.fetchClientData();
				_this.$dtoast.pop({ preset: "default", heading: `Cadeaubon vervaldatum aangepast.` });
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});
		},

		onStatusApprove: function(){
			let _this = this;
			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/customers/' + this.clientId +'/customer-information/approve',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken }
			})
			.then(function (response) {
				_this.fetchClientData();
				_this.$dtoast.pop({ preset: "default", heading: `Gegevens succesvol goedgekeurd!` });
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});
		},

		onSendSecondReminder: function(){
			let _this = this;
			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/customers/' + this.clientId +'/send/second-reminder',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken }
			})
			.then(function (response) {
				_this.fetchClientData();
				_this.$dtoast.pop({ preset: "default", heading: `Tweede herinnering verzonden!` });
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});
		},

		onUpdateStatus: function(status){
			let _this = this;
			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/customers/' + this.clientId +'/update-status',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken },
				data: { status: status }
			})
			.then(function (response) {
				_this.fetchClientData();
				_this.$dtoast.pop({ preset: "default", heading: `Status aangepast.` });
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});
		},

		onSendRequestClick: function() {
			let _this = this;			
			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/send/information-requests',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken },
				data: { id: this.clientId }
			})
			.then(function (response) {
				_this.fetchClientData();
				_this.$dtoast.pop({ preset: "default", heading: `Er werden ${response.data.data.sent_requests} e-mails succesvol verzonden!` });
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});
		},

		onSendVoucherClick: function() {
			let _this = this;
			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/send/vouchers',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken },
				data: { id: this.clientId }
			})
			.then(function (response) {
				_this.fetchClientData();
				_this.$dtoast.pop({ preset: "default", heading: `Er werden ${response.data.data.sent_vouchers} vouchers succesvol verzonden!` });
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});
		}
	}
}
</script>