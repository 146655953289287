<template src="@/views/ErrorTpl.vue"></template>

<script>
import Pagetitle from '@/global/Pagetitle.vue'

export default {
	name: 'Error',
	components: {
		Pagetitle,
	},
	props: {
		pagetitle: String
	},
	data(){
		return {
			errorCode: this.$route.query,
		};
	},
	mounted() {
		//console.log(this.$route.query);
	},
	watch: {
		// '$route': 'fetchData'
	},
	methods: {
		
	}
}
</script>