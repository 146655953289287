<template src="@/views/ClientListTpl.vue"></template>

<script>
import Pagetitle from '@/global/Pagetitle.vue'
import axios from "axios"
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import CustomModal  from '@/global/CustomModal'

export default {
	name: 'ClientList',
	components: {
		Pagetitle,
		VueGoodTable,
		CustomModal
	},
	props: {
		pagetitle: String
	},
	data(){
		return {
			statuses: this.$globalVariables.statuses,	
			statusesRelevant: [],
			table: {
				error: false,
				columns: [],
				rows: [],
				selectedRows: [],
			},
			showDeleteUserModal: false,
			showSendRequestModal: false,
			showSendVoucherModal: false,
			deleteUser: 0
		};
	},
	mounted() {
		this.fetchData();
	},
	watch: {
		'$route': 'fetchData'
	},
	methods: {
		fetchData: function() {
			let _this = this;
			axios({
				method: 'get',
				url: this.$globalVariables.apiUrl + '/customers',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken }
			})
			.then(function (response) {
				//console.log(response.data.data);
				_this.createTableData(response.data.data);
				_this.table.error = false;

				//get relevant statuses for filter
				let allStatuses = response.data.data.map(item => item.status);
				allStatuses = allStatuses.filter((item, i, ar) => ar.indexOf(item) === i);
				for (const status of allStatuses){
					_this.statusesRelevant.push(_this.statuses.find(item => item.value === status));
				}

				// _this.$globalVariables.loading = false;
			})
			.catch(function({response}){
				_this.table.error = true;
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});
		},

		createTableData: function(rows) {
			let columns = [ 
				{ label: 'Oben ID', field: 'oben_id' }, 
				{ label: 'Voornaam', field: 'first_name' },
				{ label: 'Achternaam', field: 'last_name' },
				{ label: 'E-mail', field: 'email' },
				{ label: 'Status', field: 'status', formatFn: this.formatStatus, width: '200px', filterOptions: { enabled: true, placeholder: 'Alles', filterDropdownItems: this.statusesRelevant }},
				{ label: 'Contact', field: 'can_contact', formatFn: this.formatContact, filterOptions: { enabled: true, placeholder: 'Alles', filterDropdownItems: [{ value: true, text: 'Ja' },{ value: false, text: 'Nee' }] } },
				{ label: '', field: 'acties', sortable: false },
			]

			this.table.columns = columns;
			this.table.rows = rows;
		},

		formatContact: function(value) { return (value === true ? 'Ja' : 'Nee') },
		formatStatus: function(value) {
			let status = this.statuses.find(c => c.value === value);
			return status.text;
		},
		
		onSelectedRowsChange: function(params){
			this.table.selectedRows = params.selectedRows;
			//console.log(this.table.selectedRows);
		},

		onClientClick: function(params) {
			//console.log(params);
			if (params.column.field !== 'acties') {
				this.$router.push({ path: '/clients/' + params.row.oben_id + '/' });
			}
		},

		onDeleteClient: function(){
			let _this = this;

			axios({
				method: 'delete',
				url: this.$globalVariables.apiUrl + '/customers/' + this.deleteUser +'/delete',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken }
			})
			.then(function (response) {
				//console.log(response);
				_this.fetchData();
				_this.showDeleteUserModal = false;
				_this.deleteUser = 0;
				_this.$dtoast.pop({ preset: "default", heading: `Klant verwijderd.` });
			})
			.catch(function({response}){
				_this.showDeleteUserModal = false;
				_this.deleteUser = 0;
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});
		},

		onExportClick: function() {
			let _this = this;

			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/customers/export',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken },
			})
			.then(function (response) {
				_this.$helpers.base64ToDownload(response.data.data.base64_encoded, response.data.data.mime_type, response.data.data.file_name);
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});
		},

		onSendRequestClick: function() {
			let _this = this;
			let clientIds = this.table.selectedRows.map(item => item.oben_id);
			
			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/send/information-requests',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken },
				data: {
					id: clientIds.join(',')
				}
			})
			.then(function (response) {
				_this.fetchData();
				_this.$dtoast.pop({ preset: "default", heading: `Er werden ${response.data.data.sent_requests} e-mails succesvol verzonden!` });
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});

			this.showSendRequestModal = false;
		},

		onSendVoucherClick: function() {
			let _this = this;
			let clientIds = this.table.selectedRows.map(item => item.oben_id);
			
			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/send/vouchers',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken },
				data: {
					id: clientIds.join(',')
				}
			})
			.then(function (response) {
				_this.fetchData();
				_this.$dtoast.pop({ preset: "default", heading: `Er werden ${response.data.data.sent_vouchers} vouchers succesvol verzonden!` });
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});

			this.showSendVoucherModal = false;
		}
	}
}
</script>