<template src="@/views/InvoicingTpl.vue"></template>

<script>
import Pagetitle from '@/global/Pagetitle.vue'
import axios from "axios"
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
// import { parse, format, startOfYesterday } from 'date-fns'
// import CustomModal  from '@/global/CustomModal'

export default {
	name: 'Invoicing',
	components: {
		Pagetitle,
		VueGoodTable,
		// CustomModal
	},
	props: {
		pagetitle: String
	},
	data(){
		return {
			partners: [],
			filter: {
				yearOptions: [],
				selectedYear: new Date().getFullYear(),
				selectedMonth: new Date().getMonth() + 1
			},
			table: {
				error: false,
				columns: [],
				rows: [],
				selectedRows: [],
			}
		};
	},
	mounted() {
		this.createYearOptions();

		if (this.$route.query.year) { this.filter.selectedYear = this.$route.query.year; }
		if (this.$route.query.month) { this.filter.selectedMonth = this.$route.query.month; }
		this.fetchData();
	},
	watch: {
		'$route': 'fetchData'
	},
	methods: {
		fetchData: function() {
			let _this = this;

			axios({
				method: 'get',
				url: this.$globalVariables.apiUrl + '/invoices?year='+ this.filter.selectedYear +'&month=' + this.filter.selectedMonth,   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken }
			})
			.then(function (response) {
				//console.log(response.data.data);
				if (response.data.data.length) {
					//get relevant partners for filter
					let allPartners = response.data.data.map(item => item.partner.name);
					_this.partners = allPartners.filter((item, i, ar) => ar.indexOf(item) === i);

					_this.createTableData(response.data.data);
					_this.table.error = false;

				} else {
					_this.table.error = true;
				}
			})
			.catch(function({response}){
				_this.table.error = true;
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});
		},

		createTableData: function(rows) {
			let columns = [ 
				{ label: 'Oben ID', field: 'customer.oben_id' }, 
				{ label: 'Voornaam', field: 'customer.first_name' },
				{ label: 'Achternaam', field: 'customer.last_name' },
				{ label: 'Afgehaald op', field: 'redeemed_at_formatted' }
			]

			if (this.$globalVariables.userRole === 'superadmin' || this.$globalVariables.userRole === 'oben') {
				columns.push({ label: 'Partner', field: 'partner.name', filterOptions: { enabled: true, placeholder: 'Alles', filterDropdownItems: this.partners } });
			}

			this.table.columns = columns;
			this.table.rows = rows;
		},

		createYearOptions: function() {
			let currentYear = new Date().getFullYear();
			for (let i = 2020; i <= currentYear; i++) {
				this.filter.yearOptions.push(i);
			}
			this.filter.yearOptions.reverse();
		},

		onChangeFilter: function() {
			this.$router.replace({ query: {
				'year': this.filter.selectedYear,
				'month': this.filter.selectedMonth
			} });
		},

		onExportClick: function() {
			let _this = this;

			axios({
				method: 'post',
				url: this.$globalVariables.apiUrl + '/invoices/export',   
				responseType: 'json',
				headers: { Authorization: 'Bearer ' + this.$globalVariables.apiToken },
				data: {
					year: this.filter.selectedYear,
					month: this.filter.selectedMonth
				}
			})
			.then(function (response) {
				_this.$helpers.base64ToDownload(response.data.data.base64_encoded, response.data.data.mime_type, response.data.data.file_name);
			})
			.catch(function({response}){
				_this.$dtoast.pop({ preset: "default", heading: `Oeps, er is wat misglopen!` });
			});
		}		
	}
}
</script>