<template>
	<header class="hdr">
		<div class="row">
			<div class="nav d-f center middle between">
				<div v-if="$globalVariables.userRole !== 'partner'">
					<router-link to="/clients/"><img class="d-b nav-logo w-100" src="@/static/images/logo-oben.png" alt="Oben" /></router-link>
				</div>
				<div v-if="$globalVariables.userRole === 'partner'">
					<router-link to="/invoicing/"><img class="d-b nav-logo w-100" src="@/static/images/logo-oben.png" alt="Oben" /></router-link>
				</div>

				<div id="navToggle" class="mr-xs show@sm" :class="{open: mobileNavToggle}" @click="mobileNavToggle = !mobileNavToggle">
					<span></span><span></span><span></span><span></span>
				</div>

				<div class="nav-actions d-f ml-a hide@sm" v-if="$route.name !== 'info-request'">

					<!-- partner -->
					<div class="" v-if="$globalVariables.userRole === 'partner'">
						<router-link to="/voucher/" class="btn btn-secondary mr-sm" v-if="$route.name === 'invoicing'" target="_blank">Cadeaubon scherm</router-link>
					</div>

					<!-- oben - superadmin -->					
					<div class="" v-if="$globalVariables.userRole !== 'partner'">
						<router-link to="/clients/" class="btn btn-secondary mr-sm" v-if="$route.name === 'invoicing'">Klantenbeheer</router-link>
						<router-link to="/invoicing/" class="btn btn-secondary mr-sm" v-if="$route.name === 'clients-list' || $route.name === 'clients-detail'">Facturatie</router-link>
						<router-link to="/voucher/" class="btn btn-secondary mr-sm" target="_blank">Cadeaubon scherm</router-link>
					</div>
					
					<span class="btn btn-primary" v-on:click="$helpers.onLogOut($route)">Afmelden</span>

				</div>
			</div><!-- grid -->
		</div>

		
	</header>
</template>

<script>
export default {
  name: 'Header',
  data() {
	  return {
		  mobileNavToggle: false
	  }
  }
}
</script>